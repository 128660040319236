@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.container {
  position: relative;
  display: flex;
  flex-direction: column;

  height: 305px;
  width: 100%;
  padding: 20px;

  background: url('../../images/background.svg') no-repeat;

  overflow: hidden;

  &::before {
    content: '';

    position: absolute;
    top: -25%;
    right: -23%;

    width: 100%;
    height: 150%;

    background: url('../../images/pattern.svg') repeat;
    transform: rotate(-14.85deg);
    z-index: -1;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.breadcrumbs {
  max-width: 340px;
  max-height: 36px;

  color: var(--static-white);
  line-height: $line-height-extra-small;
}

.breadcrumb {
  @include font-style-tiny;
  color: var(--static-white);
  white-space: nowrap;
  position: relative;

  &:not(:last-child) {
    padding-right: 22px;
  }

  &:hover,
  &:focus {
    color: var(--static-white);
    opacity: .65;
  }

  &:not(:last-child)::before {
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    background: url('../../../../../design-system/icons/24px/chevron-right.svg') center / contain no-repeat;
  }

  &.moveLink {
    padding-right: 18px;

    &::before {
      right: 0;
    }
  }
}

.matchName {
  @include font-style-h4($font-weight-regular);
  max-width: 360px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--static-white);
}

.matchWithLink {
  &:hover,
  &:focus {
    color: var(--static-white);
    opacity: .65;
  }
}

.matchDetailsWrapper {
  display: flex;
  align-items: center;
  max-width: 360px;
}

.liveIcon {
  @include font-style-tiny($font-weight-bold);
  padding: 1px 6px;
  margin-right: 8px;
  color: var(--text-primary);
  background: var(--static-white);
  border-radius: 6px;
  animation: liveAnimation 1.2s ease-in infinite alternate;
}

.datetime,
.matchDetails {
  @include font-style-tiny($font-weight-bold);
  color: var(--static-white);
}
//
//.hideText {
//  overflow: hidden;
//  text-overflow: ellipsis;
//}

.matchDetails {
  display: flex;
  align-items: center;

  & p {
    margin: 0;
  }

  & .firstTeamScore {
    margin-left: 4px;
  }
}

.matchText {
  margin: 0;
}

.matchScores {
  display: flex;
  align-items: center;
}

.matchPeriod {
  margin-left: 4px;
  text-transform: lowercase;
}

.additional {
  @include font-style-small;
  max-width: 370px;
  margin: 15px 0 0;
  color: var(--static-white);

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-line;

  & p {
    padding: 0;
    margin: 0;
  }
}

@keyframes liveAnimation {
  from {
    opacity: 1;
  }

  to {
    opacity: .4;
  }
}

.mainStakesAndTeamsLogosWrapper {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.teamsLogosWrapper {
  margin-left: auto;
}

.teamsLogosWrapper > *:last-child {
  margin-left: 4px;
}

.teamLogoImg {
  color: var(--static-gray-40);
  background-color: var(--static-white);
  border-radius: 8px;
  padding: 8px;
  box-sizing: content-box;
}
